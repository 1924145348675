import { assert } from "@faro-lotv/foundation";
import { Matrix4, OrthographicCamera, Plane, PointsMaterial, Quaternion, Vector2, Vector3 } from "three";
import { LodPointCloud, OptimizationHint } from "../Lod";
import { LotvRenderer } from "../ThreeExt";
import { Viewer } from "../Viewer";

// Number of pixels per meter (px/m)
const DEFAULT_RESOLUTION = 64;

// The minimum width of the orthophoto in pixels
const SVG_MINIMUM_WIDTH = 250;

// The maximum length in pixels for the long size of the final image
const IMAGE_SIZE_LIMIT = 2048;

/**
 * The parameters describing the object oriented bounding box.
 * If combined in a translation * rotation * scale matrix, they can also be
 * seen as the transformation matrix that brings the unitary bounding box
 * centered in the origin into the oriented bounding box.
 */
export type OrientedBoundingBox = {
	/** The poisition of the center of the bounding box */
	position: Vector3;
	/** The orientation of the bounding box */
	quaternion: Quaternion;
	/** The size of the bounding box along its axes */
	size: Vector3;
};

type OrthophotoOptions = {
	/** The view direction on the unitary box centered in the origin */
	viewDir: Vector3;
	/** The up direction in world coordinates of the camera */
	up: Vector3;
	/** The north direction in world coordinates of the camera */
	north: Vector3;
	/**
	 *	The resolution of the orthophoto in pixels/meters
	 *
	 *	@default 64
	 */
	resolution?: number;
};

/**
 * Clip the input point cloud by the volume provided and generate an image by orthographically
 * projecting the points on the plane provided by the user
 *
 * @param pc The input point cloud
 * @param transform The world transform applied to the point cloud
 * @param volume The properties of the oriented bounding box
 * @param options The options used to customize the result
 * @param onProgress The callback used to report the percentage of completion
 * @returns The data url of the canvas, in PNG format
 */
export async function extractOrthophoto(
	pc: LodPointCloud,
	transform: Matrix4,
	volume: OrientedBoundingBox,
	options: OrthophotoOptions,
	onProgress?: (percentage: number) => void,
): Promise<string> {
	const { viewDir, up, north } = options;

	// The height in pixels of the metadata field
	const METADATA_HEIGHT = 150;

	// Compute camera properties
	const camera = computeOrthophotoCamera(volume, viewDir, up, north);
	const sz = new Vector2(camera.right - camera.left, camera.top - camera.bottom);

	// Compute the size of the image based on the resolution
	let { resolution = DEFAULT_RESOLUTION } = options;
	let width = Math.round(resolution * sz.width);
	let height = Math.round(resolution * sz.height);

	// Clamp the image size and increase the resolution value if the image size
	// is greater than the limit. This will speed up the computation and limit the size in
	// bytes of the final image
	if (Math.max(width, height) > IMAGE_SIZE_LIMIT) {
		resolution = (resolution * IMAGE_SIZE_LIMIT) / Math.max(width, height);
		width = Math.round(resolution * sz.width);
		height = Math.round(resolution * sz.height);
	}

	// Set up clipping planes
	const clippingPlanes = createClippingPlanes(volume);

	// Draw the scene
	const canvasURL = await renderScene(pc, transform, clippingPlanes, width, height, camera, onProgress);

	// Create the SVG
	const svgWidth = Math.max(width, SVG_MINIMUM_WIDTH);
	const svgHeight = height + METADATA_HEIGHT;
	const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	svg.setAttribute("width", `${svgWidth}px`);
	svg.setAttribute("height", `${svgHeight}px`);

	// Add the canvas image to the svg
	const img = document.createElementNS("http://www.w3.org/2000/svg", "image");
	img.setAttribute("x", "0");
	img.setAttribute("y", "0");
	img.setAttribute("width", "100%");
	img.setAttribute("height", `${height}px`);
	img.setAttribute("href", canvasURL);
	svg.appendChild(img);

	// Create the foreign objetct containing the metedata of the orthophoto
	const foreignObject = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject");
	foreignObject.setAttribute("x", "0");
	foreignObject.setAttribute("y", `${height}px`);
	foreignObject.setAttribute("width", "100%");
	foreignObject.setAttribute("height", `${METADATA_HEIGHT}px`);
	svg.appendChild(foreignObject);

	// Create the div element representing the metadata of the orthophoto
	const metadata = createMetadata(svgWidth, METADATA_HEIGHT, sz, resolution);
	foreignObject.appendChild(metadata);

	// Draw the svg on an image and create the blob of the rasterized canvas
	const data = new XMLSerializer().serializeToString(svg);
	const svgUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(data)}`;

	const image = await loadImage(svgUrl);
	const canvas = document.createElement("canvas");

	canvas.setAttribute("width", `${svgWidth}px`);
	canvas.setAttribute("height", `${svgHeight}px`);
	const context = canvas.getContext("2d");
	assert(context, "Invalid 2D context");

	context.drawImage(image, 0, 0, svgWidth, svgHeight);
	return canvas.toDataURL();
}

/**
 * @returns An HTML ImageElement extracted from the input url
 * @param url The url to the image
 */
function loadImage(url: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image();

		image.onload = () => {
			resolve(image);
		};

		image.onerror = reject;

		image.src = url;
	});
}

/** The const value used to compare values close to zero */
const EPSILON = 1e-5;

/**
 * Compute the orthographic camera to render the orthophoto
 *
 * @param volume The oriented bounding box observed by the camera
 * @param viewDir The view direction of the camera
 * @param upAxis The up direction in world coordinates
 * @param northAxis The north direction in world coordinates
 * @returns The camera
 */
export function computeOrthophotoCamera(
	volume: OrientedBoundingBox,
	viewDir: Vector3,
	upAxis: Vector3,
	northAxis: Vector3,
): OrthographicCamera {
	// Compute the matrix that brings the unitary bounding box into the new object oriented box
	const matrix = new Matrix4().compose(volume.position, volume.quaternion, volume.size);

	// Compute the center of the plane on the unitary box
	const positionOnUnitBox = new Vector3().sub(viewDir).multiplyScalar(0.5);

	// Check what's the up of the camera: it's the up vector or the north if the up is aligned with the view direction
	const up = upAxis.clone();
	if (new Vector3().crossVectors(up, viewDir).length() < EPSILON) {
		up.copy(northAxis);
	}
	// Check what's the right of the camera
	const right = new Vector3().crossVectors(up, viewDir.clone().multiplyScalar(-1)).normalize();

	// Guarantee that the up direction is orthonormal to the right and view ones
	up.copy(new Vector3().crossVectors(right, viewDir));

	// Compute the world position of the top and right positions on the unitary box
	const pTop = up.clone().multiplyScalar(0.5).applyMatrix4(matrix);
	const pRight = right.clone().multiplyScalar(0.5).applyMatrix4(matrix);

	// Compute the size of the plane
	const size = new Vector2(pRight.sub(volume.position).length() * 2, pTop.sub(volume.position).length() * 2);

	const position = positionOnUnitBox.clone().applyMatrix4(matrix);
	const pFront = positionOnUnitBox.clone().add(viewDir).applyMatrix4(matrix);

	// Small offset added to the camera position along the view direction to avoid clipping artifacts
	const OFFSET = 0.1;
	const camera = new OrthographicCamera(
		-size.x * 0.5,
		size.x * 0.5,
		size.y * 0.5,
		-size.y * 0.5,
		0,
		new Vector3().subVectors(position, pFront).length() + OFFSET * 2,
	);
	camera.position.copy(positionOnUnitBox);
	camera.up.copy(up);
	camera.lookAt(new Vector3(0, 0, 0));
	camera.applyMatrix4(new Matrix4().compose(volume.position, volume.quaternion, volume.size));
	camera.scale.set(1, 1, 1);

	// Move the camera position a little bit behind
	camera.position.sub(viewDir.clone().applyQuaternion(volume.quaternion).multiplyScalar(OFFSET));

	return camera;
}

/**
 * Extract the six clipping planes describing the input oriented bounding box
 *
 * @param volume The oriented bounding box
 * @returns The list of six planes
 */
function createClippingPlanes(volume: OrientedBoundingBox): Plane[] {
	const volumeMatrix = new Matrix4().compose(volume.position, volume.quaternion, volume.size);
	const normals = [
		new Vector3(1, 0, 0),
		new Vector3(-1, 0, 0),
		new Vector3(0, 1, 0),
		new Vector3(0, -1, 0),
		new Vector3(0, 0, 1),
		new Vector3(0, 0, -1),
	];
	return normals.map((n) =>
		new Plane().setFromNormalAndCoplanarPoint(n, n.clone().multiplyScalar(-0.5)).applyMatrix4(volumeMatrix),
	);
}

/**
 * Render the orthographic projection of the cloud
 *
 * @param pc The cloud to render
 * @param transform The world transform of the point cloud
 * @param clippingPlanes The clipping planes used to render the scene
 * @param width The width of the canvas in pixels
 * @param height The height of the canvas in pixels
 * @param camera The orthographic camera used to render the scene
 * @param onProgress The callback used to report the percentage of completition
 * @returns The canvas content URL
 */
async function renderScene(
	pc: LodPointCloud,
	transform: Matrix4,
	clippingPlanes: Plane[],
	width: number,
	height: number,
	camera: OrthographicCamera,
	onProgress?: (percentage: number) => void,
): Promise<string> {
	const renderer = new LotvRenderer({ alpha: true });
	renderer.localClippingEnabled = true;

	const viewer = new Viewer({ renderer, camera });
	viewer.scene.background = null;
	viewer.renderer.setSize(width, height, false);
	viewer.camera.updateMatrixWorld();

	const view = pc.createView();
	view.material = new PointsMaterial({ vertexColors: true, sizeAttenuation: false, size: 1 });
	view.visibleNodesStrategy.optimizationHint = OptimizationHint.cameraChangingOnce;
	view.visibleNodesStrategy.maxPointsInGpu = Number.POSITIVE_INFINITY;

	view.material.clippingPlanes = clippingPlanes;
	view.applyMatrix4(transform);
	view.updateMatrixWorld();
	viewer.scene.add(view);

	// TODO: verify how much this parameter speeds up the execution
	const { maxNodesToDownloadAtOnce } = view.lodTreeFetcher;
	view.lodTreeFetcher.maxNodesToDownloadAtOnce = 10;

	const size = viewer.renderer.getSize(new Vector2());

	// Cap the maximum percentage value so that we don't report a jumping progress
	let maxPercentage = 0;
	function progress(percentage: number): void {
		maxPercentage = Math.max(percentage, maxPercentage);
		onProgress?.(maxPercentage);
	}

	// Fetch all nodes
	let done = false;
	const updateCamera = (): void => {
		view.updateCamera(camera, size);
		if (view.nodesInGPU.size !== view.visibleNodesCount) {
			progress(Math.round((view.nodesInGPU.size / view.visibleNodesCount) * 100));
		}
		view.visibleNodesStrategy.optimizationHint = OptimizationHint.cameraSteady;
		if (!done) {
			requestAnimationFrame(updateCamera);
		}
	};
	const fetchNodes = new Promise<void>((resolve) => {
		view.allPointsReceived.on(() => {
			const { visibleNodesCount } = view;
			view.updateCamera(camera, size);
			// This check is necessary because if we have proxy nodes, their number of children is 0
			// So the visibility algorithm (the one that updates visibleNodesCount) does not take into account them
			// and the fetcher point cloud could emit an allPointsReceived even if it's not technically true
			if (view.visibleNodesCount === visibleNodesCount) {
				onProgress?.(100);
				view.lodTreeFetcher.maxNodesToDownloadAtOnce = maxNodesToDownloadAtOnce;
				resolve();
			}
		});
	});

	updateCamera();

	await fetchNodes;
	done = true;
	viewer.drawOnce();
	view.dispose();

	return viewer.renderer.domElement.toDataURL();
}

/**
 * Create the div element containing the metadata of the orthophoto
 *
 * @param width The width of the orthophoto, in pixels
 * @param height The height of the metadata field, in pixels
 * @param size The size of the orthophoto, in meters
 * @param resolution The resolution of the orthophoto in pixels/meters
 * @returns The div element
 */
function createMetadata(width: number, height: number, size: Vector2, resolution: number): HTMLDivElement {
	const METERS_TO_FEET = 3.28084;

	const numSegmentsInMeters = Math.floor(width / resolution);
	const metersNumberLine = createNumberList(resolution, numSegmentsInMeters, 1);
	const metersDottedLine =
		createDottedLine(resolution / 10, 10, "#E8A600") +
		createDottedLine(resolution, numSegmentsInMeters - 1, "#E8A600");

	const resolutionInTensOfFeet = Math.round(resolution / (METERS_TO_FEET * 0.1));
	const numSegmentsInFeet = Math.floor(width / resolutionInTensOfFeet);
	const feetNumberLine = createNumberList(resolutionInTensOfFeet, numSegmentsInFeet, 10);
	const feetDottedLine =
		createDottedLine(resolutionInTensOfFeet / 10, 10, "#1F65F0") +
		createDottedLine(resolutionInTensOfFeet, numSegmentsInFeet - 1, "#1F65F0");

	const metadata = document.createElement("div");
	metadata.innerHTML = `
    <div style="padding-top: 16px; padding-left:1px; display:flex;flex-direction:column;gap: 8px; height:${height}px; font-size: 12px">
        <div style="display:flex;">
            ${metersNumberLine}
        </div>
		 <div style="display:flex;">
            ${metersDottedLine}
        </div>
		 <div style="display:flex;">
            ${feetDottedLine}
        </div>
		<div style="display:flex;">
            ${feetNumberLine}
        </div>
        <div>
            ${resolution.toFixed(2)}px/m | ${(resolutionInTensOfFeet / 10).toFixed(2)}px/ft
        <div>
        <div>
            ${size.width.toFixed(2)}m x ${size.height.toFixed(2)}m | ${(size.width * METERS_TO_FEET).toFixed(2)}ft x ${(size.height * METERS_TO_FEET).toFixed(2)}ft
        <div>
    </div>
    `;
	return metadata;
}

/**
 * Create a list of divs containing a number that is the index of the div in the list
 *
 * @param width The width in pixels of a div
 * @param numSegments The number of divs in the list
 * @param multiplier The number for which the index of the div is multiplied before rendering it
 * @returns An HTML string collecting the list of divs
 */
function createNumberList(width: number, numSegments: number, multiplier: number): string {
	let text = "";
	for (let i = 0; i <= numSegments; ++i) {
		text += `<div style="width:${width}px;flex-shrink: 0; font-size: 12px;">${i * multiplier}</div>`;
	}
	return text;
}

/**
 * Create a list of divs that are just rectangles alternatively colored
 *
 * @param width The width in pixels of a div
 * @param numSegments The number of divs in the list
 * @param inputColor The color used for divs with an even index. Odd divs are always white.
 * @returns An HTML string collecting the list of divs
 */
function createDottedLine(width: number, numSegments: number, inputColor: string): string {
	let text = "";
	for (let i = 0; i < numSegments; ++i) {
		const color = i % 2 === 0 ? inputColor : "white";
		text += `
		 <div style="width:${width}px;background-color:${color};height:10px;outline-width:1px;outline-style:solid"></div>
		 `;
	}
	return text;
}
